/* tslint:disable */
/* eslint-disable */
/**
 * Ravimoshark Api Monolithic Implementation
 * Ravimoshark Monolithic API interacts with all SAV tables and services.
 *
 * The version of the OpenAPI document: 0.3.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArticlesControllerCreate400Response
 */
export interface ArticlesControllerCreate400Response {
    /**
     * 
     * @type {number}
     * @memberof ArticlesControllerCreate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlesControllerCreate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerCreate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesControllerCreate409Response
 */
export interface ArticlesControllerCreate409Response {
    /**
     * 
     * @type {number}
     * @memberof ArticlesControllerCreate409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerCreate409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesControllerFindAll200Response
 */
export interface ArticlesControllerFindAll200Response {
    /**
     * 
     * @type {Array<ListesPieces>}
     * @memberof ArticlesControllerFindAll200Response
     */
    'items'?: Array<ListesPieces>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof ArticlesControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {ArticlesControllerFindAll200ResponseLinks}
     * @memberof ArticlesControllerFindAll200Response
     */
    'links'?: ArticlesControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface ArticlesControllerFindAll200ResponseLinks
 */
export interface ArticlesControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesControllerFindOne404Response
 */
export interface ArticlesControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof ArticlesControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesControllerRemove500Response
 */
export interface ArticlesControllerRemove500Response {
    /**
     * 
     * @type {number}
     * @memberof ArticlesControllerRemove500Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerRemove500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesControllerUpdate400Response
 */
export interface ArticlesControllerUpdate400Response {
    /**
     * 
     * @type {number}
     * @memberof ArticlesControllerUpdate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlesControllerUpdate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesControllerUpdate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesSageControllerFindAll200Response
 */
export interface ArticlesSageControllerFindAll200Response {
    /**
     * 
     * @type {Array<VDevRavimoArticles>}
     * @memberof ArticlesSageControllerFindAll200Response
     */
    'items'?: Array<VDevRavimoArticles>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof ArticlesSageControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {ArticlesSageControllerFindAll200ResponseLinks}
     * @memberof ArticlesSageControllerFindAll200Response
     */
    'links'?: ArticlesSageControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface ArticlesSageControllerFindAll200ResponseLinks
 */
export interface ArticlesSageControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof ArticlesSageControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesSageControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesSageControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesSageControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface ClientGroups
 */
export interface ClientGroups {
    /**
     * 
     * @type {string}
     * @memberof ClientGroups
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ClientGroups
     */
    'branches': number;
}
/**
 * 
 * @export
 * @interface ClientProhibitedControllerAllow409Response
 */
export interface ClientProhibitedControllerAllow409Response {
    /**
     * 
     * @type {number}
     * @memberof ClientProhibitedControllerAllow409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientProhibitedControllerAllow409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ClientProhibitedControllerFindAll200ResponseInner
 */
export interface ClientProhibitedControllerFindAll200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof ClientProhibitedControllerFindAll200ResponseInner
     */
    'refClient'?: string;
}
/**
 * 
 * @export
 * @interface ClientProhibitedControllerProhibit400Response
 */
export interface ClientProhibitedControllerProhibit400Response {
    /**
     * 
     * @type {number}
     * @memberof ClientProhibitedControllerProhibit400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientProhibitedControllerProhibit400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientProhibitedControllerProhibit400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ClientProhibitedControllerProhibit409Response
 */
export interface ClientProhibitedControllerProhibit409Response {
    /**
     * 
     * @type {number}
     * @memberof ClientProhibitedControllerProhibit409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientProhibitedControllerProhibit409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientStatus = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Allowed: 'allowed',
    Blocked: 'blocked',
    All: 'all'
} as const;

export type ClientStatus = typeof ClientStatus[keyof typeof ClientStatus];


/**
 * 
 * @export
 * @interface ClientsControllerCreate400Response
 */
export interface ClientsControllerCreate400Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerCreate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsControllerCreate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerCreate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerCreate409Response
 */
export interface ClientsControllerCreate409Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerCreate409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerCreate409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerCreate500Response
 */
export interface ClientsControllerCreate500Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerCreate500Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerCreate500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindAll200Response
 */
export interface ClientsControllerFindAll200Response {
    /**
     * 
     * @type {Array<ListesClients>}
     * @memberof ClientsControllerFindAll200Response
     */
    'items'?: Array<ListesClients>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof ClientsControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseLinks}
     * @memberof ClientsControllerFindAll200Response
     */
    'links'?: ClientsControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindAll200ResponseLinks
 */
export interface ClientsControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindAll200ResponseMeta
 */
export interface ClientsControllerFindAll200ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll200ResponseMeta
     */
    'totalItems': number;
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll200ResponseMeta
     */
    'itemCount': number;
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll200ResponseMeta
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll200ResponseMeta
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll200ResponseMeta
     */
    'currentPage': number;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindAll400Response
 */
export interface ClientsControllerFindAll400Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindAll400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsControllerFindAll400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindAll400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindOne400Response
 */
export interface ClientsControllerFindOne400Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindOne400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsControllerFindOne400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindOne400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerFindOne404Response
 */
export interface ClientsControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ClientsControllerRemove500Response
 */
export interface ClientsControllerRemove500Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsControllerRemove500Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsControllerRemove500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ClientsSageControllerFindAll200Response
 */
export interface ClientsSageControllerFindAll200Response {
    /**
     * 
     * @type {Array<VDevRavimoClientsFinal>}
     * @memberof ClientsSageControllerFindAll200Response
     */
    'items'?: Array<VDevRavimoClientsFinal>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof ClientsSageControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {ClientsSageControllerFindAll200ResponseLinks}
     * @memberof ClientsSageControllerFindAll200Response
     */
    'links'?: ClientsSageControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface ClientsSageControllerFindAll200ResponseLinks
 */
export interface ClientsSageControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof ClientsSageControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsSageControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsSageControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsSageControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface ClientsSageControllerFindOne404Response
 */
export interface ClientsSageControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof ClientsSageControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsSageControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DataMigrationRequest
 */
export interface DataMigrationRequest {
    /**
     * 
     * @type {string}
     * @memberof DataMigrationRequest
     */
    'referenceFrom': string;
    /**
     * 
     * @type {string}
     * @memberof DataMigrationRequest
     */
    'referenceTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof DataMigrationRequest
     */
    'skipCoordinates'?: boolean;
}
/**
 * 
 * @export
 * @interface ListesClients
 */
export interface ListesClients {
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'referenceClient': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'adresse': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'abrege': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'nomClient': string;
    /**
     * 
     * @type {number}
     * @memberof ListesClients
     */
    'codePostal': number | null;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'pays': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'etat': string | null;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'complementAdresse': string | null;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'groupe': string;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'statutGroupe': string;
    /**
     * 
     * @type {number}
     * @memberof ListesClients
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof ListesClients
     */
    'long': number | null;
    /**
     * 
     * @type {string}
     * @memberof ListesClients
     */
    'email': string;
    /**
     * 
     * @type {ClientStatus}
     * @memberof ListesClients
     */
    'status': ClientStatus;
}


/**
 * 
 * @export
 * @interface ListesPieces
 */
export interface ListesPieces {
    /**
     * 
     * @type {string}
     * @memberof ListesPieces
     */
    'referenceArticle': string;
    /**
     * 
     * @type {string}
     * @memberof ListesPieces
     */
    'designationArticle': string;
    /**
     * 
     * @type {string}
     * @memberof ListesPieces
     */
    'intituleFamille': string;
    /**
     * 
     * @type {string}
     * @memberof ListesPieces
     */
    'statut': string | null;
    /**
     * 
     * @type {number}
     * @memberof ListesPieces
     */
    'prixVente': number;
    /**
     * 
     * @type {number}
     * @memberof ListesPieces
     */
    'uniteVente': number;
    /**
     * 
     * @type {number}
     * @memberof ListesPieces
     */
    'dernierPrixAchat': number;
}
/**
 * 
 * @export
 * @interface PiecesIntervention
 */
export interface PiecesIntervention {
    /**
     * 
     * @type {number}
     * @memberof PiecesIntervention
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PiecesIntervention
     */
    'quantite': number;
    /**
     * 
     * @type {string}
     * @memberof PiecesIntervention
     */
    'dateAppel': string;
    /**
     * 
     * @type {ListesPieces}
     * @memberof PiecesIntervention
     */
    'article'?: ListesPieces | null;
    /**
     * 
     * @type {string}
     * @memberof PiecesIntervention
     */
    'refArticle': string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerCreate400Response
 */
export interface TacheInterventionsControllerCreate400Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerCreate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TacheInterventionsControllerCreate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerCreate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerCreate409Response
 */
export interface TacheInterventionsControllerCreate409Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerCreate409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerCreate409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerFindAll200Response
 */
export interface TacheInterventionsControllerFindAll200Response {
    /**
     * 
     * @type {Array<PiecesIntervention>}
     * @memberof TacheInterventionsControllerFindAll200Response
     */
    'items'?: Array<PiecesIntervention>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof TacheInterventionsControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {TacheInterventionsControllerFindAll200ResponseLinks}
     * @memberof TacheInterventionsControllerFindAll200Response
     */
    'links'?: TacheInterventionsControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerFindAll200ResponseLinks
 */
export interface TacheInterventionsControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerFindAll400Response
 */
export interface TacheInterventionsControllerFindAll400Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerFindAll400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TacheInterventionsControllerFindAll400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindAll400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerFindOne400Response
 */
export interface TacheInterventionsControllerFindOne400Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerFindOne400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TacheInterventionsControllerFindOne400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindOne400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerFindOne404Response
 */
export interface TacheInterventionsControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TacheInterventionsControllerUpdate400Response
 */
export interface TacheInterventionsControllerUpdate400Response {
    /**
     * 
     * @type {number}
     * @memberof TacheInterventionsControllerUpdate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TacheInterventionsControllerUpdate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TacheInterventionsControllerUpdate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface Taches
 */
export interface Taches {
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'dateAppel': string;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'refClient': string | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'secteur': number | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'typeInter': string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'descriptionPanne': string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'dateDepan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'descriptionDepan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'technician': string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'initiator': string | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'enroute': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Taches
     */
    'savPayant': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'modele': string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'numeroSerie': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Taches
     */
    'reserve'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'mo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'nomClient': string | null;
    /**
     * 
     * @type {string}
     * @memberof Taches
     */
    'codePostal': string | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'longitude': number | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'latitude': number | null;
    /**
     * 
     * @type {number}
     * @memberof Taches
     */
    'rating': number | null;
    /**
     * 
     * @type {Array<PiecesIntervention>}
     * @memberof Taches
     */
    'piecesInterventions': Array<PiecesIntervention>;
}
/**
 * 
 * @export
 * @interface TachesControllerCreate400Response
 */
export interface TachesControllerCreate400Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerCreate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TachesControllerCreate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerCreate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerCreate409Response
 */
export interface TachesControllerCreate409Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerCreate409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerCreate409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerFindAll200Response
 */
export interface TachesControllerFindAll200Response {
    /**
     * 
     * @type {Array<Taches>}
     * @memberof TachesControllerFindAll200Response
     */
    'items'?: Array<Taches>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof TachesControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {TachesControllerFindAll200ResponseLinks}
     * @memberof TachesControllerFindAll200Response
     */
    'links'?: TachesControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface TachesControllerFindAll200ResponseLinks
 */
export interface TachesControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerFindAll400Response
 */
export interface TachesControllerFindAll400Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerFindAll400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TachesControllerFindAll400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindAll400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerFindOne404Response
 */
export interface TachesControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerRemove404Response
 */
export interface TachesControllerRemove404Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerRemove404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerRemove404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerRemove500Response
 */
export interface TachesControllerRemove500Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerRemove500Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerRemove500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerUpdate400Response
 */
export interface TachesControllerUpdate400Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerUpdate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TachesControllerUpdate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerUpdate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TachesControllerUpdate404Response
 */
export interface TachesControllerUpdate404Response {
    /**
     * 
     * @type {number}
     * @memberof TachesControllerUpdate404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TachesControllerUpdate404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TachesDto
 */
export interface TachesDto {
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'dateAppel': string;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'refClient': string | null;
    /**
     * 
     * @type {number}
     * @memberof TachesDto
     */
    'secteur': number | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'typeInter': string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'descriptionPanne': string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'dateDepan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'descriptionDepan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'technician': string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'initiator': string | null;
    /**
     * 
     * @type {number}
     * @memberof TachesDto
     */
    'enroute': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TachesDto
     */
    'savPayant': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'modele': string | null;
    /**
     * 
     * @type {string}
     * @memberof TachesDto
     */
    'numeroSerie': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TachesDto
     */
    'reserve'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TachesDto
     */
    'mo'?: number | null;
}
/**
 * 
 * @export
 * @interface TechniciansControllerCreate400Response
 */
export interface TechniciansControllerCreate400Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerCreate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansControllerCreate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerCreate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerCreate409Response
 */
export interface TechniciansControllerCreate409Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerCreate409Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerCreate409Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerFindAll200Response
 */
export interface TechniciansControllerFindAll200Response {
    /**
     * 
     * @type {Array<ZoneResa>}
     * @memberof TechniciansControllerFindAll200Response
     */
    'items'?: Array<ZoneResa>;
    /**
     * 
     * @type {ClientsControllerFindAll200ResponseMeta}
     * @memberof TechniciansControllerFindAll200Response
     */
    'meta'?: ClientsControllerFindAll200ResponseMeta;
    /**
     * 
     * @type {TechniciansControllerFindAll200ResponseLinks}
     * @memberof TechniciansControllerFindAll200Response
     */
    'links'?: TechniciansControllerFindAll200ResponseLinks;
}
/**
 * 
 * @export
 * @interface TechniciansControllerFindAll200ResponseLinks
 */
export interface TechniciansControllerFindAll200ResponseLinks {
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindAll200ResponseLinks
     */
    'first'?: string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindAll200ResponseLinks
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindAll200ResponseLinks
     */
    'next'?: string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindAll200ResponseLinks
     */
    'last'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerFindAll400Response
 */
export interface TechniciansControllerFindAll400Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerFindAll400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansControllerFindAll400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindAll400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerFindOne404Response
 */
export interface TechniciansControllerFindOne404Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerFindOne404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerFindOne404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerRemove404Response
 */
export interface TechniciansControllerRemove404Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerRemove404Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerRemove404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerRemove500Response
 */
export interface TechniciansControllerRemove500Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerRemove500Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerRemove500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TechniciansControllerUpdate400Response
 */
export interface TechniciansControllerUpdate400Response {
    /**
     * 
     * @type {number}
     * @memberof TechniciansControllerUpdate400Response
     */
    'statusCode'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansControllerUpdate400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TechniciansControllerUpdate400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface VDevRavimoArticles
 */
export interface VDevRavimoArticles {
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoArticles
     */
    'refArticle': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoArticles
     */
    'designationArticle': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoArticles
     */
    'statut': string | null;
    /**
     * 
     * @type {number}
     * @memberof VDevRavimoArticles
     */
    'prixVente': number;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoArticles
     */
    'intituleFamille': string;
    /**
     * 
     * @type {number}
     * @memberof VDevRavimoArticles
     */
    'dernierPrixAchat': number;
    /**
     * 
     * @type {number}
     * @memberof VDevRavimoArticles
     */
    'uniteVente': number;
}
/**
 * 
 * @export
 * @interface VDevRavimoClientsFinal
 */
export interface VDevRavimoClientsFinal {
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'numero': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'intitule': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'actifSommeil': string | null;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'groupes': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'statutGroupe': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'abrege': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'adresse': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'complementAdresse': string | null;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'cp': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'ville': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'pays': string;
    /**
     * 
     * @type {string}
     * @memberof VDevRavimoClientsFinal
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ZoneResa
 */
export interface ZoneResa {
    /**
     * 
     * @type {string}
     * @memberof ZoneResa
     */
    'technicien': string;
    /**
     * 
     * @type {number}
     * @memberof ZoneResa
     */
    'secteur': number;
    /**
     * 
     * @type {string}
     * @memberof ZoneResa
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof ZoneResa
     */
    'mdp': string;
}

/**
 * ArticlesApi - axios parameter creator
 * @export
 */
export const ArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate: async (listesPieces: ListesPieces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listesPieces' is not null or undefined
            assertParamExists('articlesControllerCreate', 'listesPieces', listesPieces)
            const localVarPath = `/old/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesPieces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindAll: async (name?: string, family?: string, status?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (family !== undefined) {
                localVarQueryParameter['family'] = family;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerFindOne', 'reference', reference)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/articles/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerRemove: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerRemove', 'reference', reference)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdate: async (reference: string, listesPieces: ListesPieces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerUpdate', 'reference', reference)
            // verify required parameter 'listesPieces' is not null or undefined
            assertParamExists('articlesControllerUpdate', 'listesPieces', listesPieces)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesPieces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindAll: async (referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sage/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (referenceFrom !== undefined) {
                localVarQueryParameter['referenceFrom'] = referenceFrom;
            }

            if (referenceTo !== undefined) {
                localVarQueryParameter['referenceTo'] = referenceTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesSageControllerFindOne', 'reference', reference)
            const localVarPath = `/sage/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationArticlesControllerCreate: async (dataMigrationRequest: DataMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataMigrationRequest' is not null or undefined
            assertParamExists('migrationArticlesControllerCreate', 'dataMigrationRequest', dataMigrationRequest)
            const localVarPath = `/migration/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticlesApi - functional programming interface
 * @export
 */
export const ArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerCreate(listesPieces: ListesPieces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerCreate(listesPieces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerFindAll(name, family, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesPieces>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerRemove(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerRemove(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerUpdate(reference, listesPieces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesSageControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesSageControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VDevRavimoArticles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesSageControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesPieces>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationArticlesControllerCreate(dataMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticlesApi - factory interface
 * @export
 */
export const ArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticlesApiFp(configuration)
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate(listesPieces: ListesPieces, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerCreate(listesPieces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: any): AxiosPromise<ArticlesControllerFindAll200Response> {
            return localVarFp.articlesControllerFindAll(name, family, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindOne(reference: string, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData(options?: any): AxiosPromise<Array<ListesPieces>> {
            return localVarFp.articlesControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerRemove(reference: string, options?: any): AxiosPromise<void> {
            return localVarFp.articlesControllerRemove(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerUpdate(reference, listesPieces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: any): AxiosPromise<ArticlesSageControllerFindAll200Response> {
            return localVarFp.articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindOne(reference: string, options?: any): AxiosPromise<VDevRavimoArticles> {
            return localVarFp.articlesSageControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: any): AxiosPromise<Array<ListesPieces>> {
            return localVarFp.migrationArticlesControllerCreate(dataMigrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticlesApi - object-oriented interface
 * @export
 * @class ArticlesApi
 * @extends {BaseAPI}
 */
export class ArticlesApi extends BaseAPI {
    /**
     * 
     * @param {ListesPieces} listesPieces 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerCreate(listesPieces: ListesPieces, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerCreate(listesPieces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [family] 
     * @param {string} [status] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerFindAll(name, family, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerGetDropdownData(options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerRemove(reference: string, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerRemove(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {ListesPieces} listesPieces 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesControllerUpdate(reference, listesPieces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [referenceFrom] 
     * @param {string} [referenceTo] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public articlesSageControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).articlesSageControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataMigrationRequest} dataMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).migrationArticlesControllerCreate(dataMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientGroupsApi - axios parameter creator
 * @export
 */
export const ClientGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-groups/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientGroupsApi - functional programming interface
 * @export
 */
export const ClientGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientGroups>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGroupsControllerGetDropdownData(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientGroupsApi - factory interface
 * @export
 */
export const ClientGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData(lookUp?: string, options?: any): AxiosPromise<Array<ClientGroups>> {
            return localVarFp.clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientGroupsApi - object-oriented interface
 * @export
 * @class ClientGroupsApi
 * @extends {BaseAPI}
 */
export class ClientGroupsApi extends BaseAPI {
    /**
     * 
     * @param {string} [lookUp] name, to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientGroupsApi
     */
    public clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig) {
        return ClientGroupsApiFp(this.configuration).clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-groups/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerAllow', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/allow/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-prohibited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerProhibit', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/prohibit/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate: async (listesClients: ListesClients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listesClients' is not null or undefined
            assertParamExists('clientsControllerCreate', 'listesClients', listesClients)
            const localVarPath = `/old/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesClients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll: async (status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerFindOne', 'refClient', refClient)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData: async (status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/clients/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemove: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerRemove', 'refClient', refClient)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate: async (refClient: string, listesClients: ListesClients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'refClient', refClient)
            // verify required parameter 'listesClients' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'listesClients', listesClients)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesClients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMigrationControllerCreate: async (dataMigrationRequest: DataMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataMigrationRequest' is not null or undefined
            assertParamExists('clientsMigrationControllerCreate', 'dataMigrationRequest', dataMigrationRequest)
            const localVarPath = `/migration/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindAll: async (referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sage/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (referenceFrom !== undefined) {
                localVarQueryParameter['referenceFrom'] = referenceFrom;
            }

            if (referenceTo !== undefined) {
                localVarQueryParameter['referenceTo'] = referenceTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('clientsSageControllerFindOne', 'reference', reference)
            const localVarPath = `/sage/clients/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientGroups>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGroupsControllerGetDropdownData(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerAllow(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerFindAll(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerProhibit(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerCreate(listesClients: ListesClients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerCreate(listesClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindAll(status, lookUp, group, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindOne(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindOne(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesClients>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerGetDropdownData(status, lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerRemove(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerRemove(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerUpdate(refClient, listesClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesClients>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMigrationControllerCreate(dataMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsSageControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsSageControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VDevRavimoClientsFinal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsSageControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData(lookUp?: string, options?: any): AxiosPromise<Array<ClientGroups>> {
            return localVarFp.clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerAllow(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll(lookUp?: string, options?: any): AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>> {
            return localVarFp.clientProhibitedControllerFindAll(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerProhibit(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate(listesClients: ListesClients, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerCreate(listesClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: any): AxiosPromise<ClientsControllerFindAll200Response> {
            return localVarFp.clientsControllerFindAll(status, lookUp, group, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne(refClient: string, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerFindOne(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: any): AxiosPromise<Array<ListesClients>> {
            return localVarFp.clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemove(refClient: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerRemove(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerUpdate(refClient, listesClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: any): AxiosPromise<Array<ListesClients>> {
            return localVarFp.clientsMigrationControllerCreate(dataMigrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: any): AxiosPromise<ClientsSageControllerFindAll200Response> {
            return localVarFp.clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindOne(reference: string, options?: any): AxiosPromise<VDevRavimoClientsFinal> {
            return localVarFp.clientsSageControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @param {string} [lookUp] name, to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientProhibitedControllerAllow(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lookUp] reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientProhibitedControllerFindAll(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientProhibitedControllerProhibit(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListesClients} listesClients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerCreate(listesClients: ListesClients, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerCreate(listesClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
     * @param {string} [lookUp] name, nickname or reference to search for
     * @param {string} [group] group name to search for
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindAll(status, lookUp, group, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindOne(refClient: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindOne(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
     * @param {string} [lookUp] name, nickname or reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerRemove(refClient: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerRemove(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {ListesClients} listesClients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerUpdate(refClient, listesClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataMigrationRequest} dataMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsMigrationControllerCreate(dataMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [referenceFrom] 
     * @param {string} [referenceTo] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsSageControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsSageControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DropdownApi - axios parameter creator
 * @export
 */
export const DropdownApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/articles/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-groups/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData: async (status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/clients/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/technicians/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DropdownApi - functional programming interface
 * @export
 */
export const DropdownApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DropdownApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesPieces>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientGroups>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGroupsControllerGetDropdownData(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesClients>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerGetDropdownData(status, lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneResa>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DropdownApi - factory interface
 * @export
 */
export const DropdownApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DropdownApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData(options?: any): AxiosPromise<Array<ListesPieces>> {
            return localVarFp.articlesControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData(lookUp?: string, options?: any): AxiosPromise<Array<ClientGroups>> {
            return localVarFp.clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: any): AxiosPromise<Array<ListesClients>> {
            return localVarFp.clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData(options?: any): AxiosPromise<Array<ZoneResa>> {
            return localVarFp.techniciansControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DropdownApi - object-oriented interface
 * @export
 * @class DropdownApi
 * @extends {BaseAPI}
 */
export class DropdownApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropdownApi
     */
    public articlesControllerGetDropdownData(options?: AxiosRequestConfig) {
        return DropdownApiFp(this.configuration).articlesControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lookUp] name, to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropdownApi
     */
    public clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig) {
        return DropdownApiFp(this.configuration).clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
     * @param {string} [lookUp] name, nickname or reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropdownApi
     */
    public clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig) {
        return DropdownApiFp(this.configuration).clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropdownApi
     */
    public techniciansControllerGetDropdownData(options?: AxiosRequestConfig) {
        return DropdownApiFp(this.configuration).techniciansControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InterventionsApi - axios parameter creator
 * @export
 */
export const InterventionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate: async (piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerCreate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll: async (dateAppel?: string, refArticle?: string, limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dateAppel !== undefined) {
                localVarQueryParameter['dateAppel'] = (dateAppel as any instanceof Date) ?
                    (dateAppel as any).toISOString() :
                    dateAppel;
            }

            if (refArticle !== undefined) {
                localVarQueryParameter['refArticle'] = refArticle;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerFindOne', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerRemove', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate: async (id: number, piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'id', id)
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterventionsApi - functional programming interface
 * @export
 */
export const InterventionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterventionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerCreate(piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TacheInterventionsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerUpdate(id, piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterventionsApi - factory interface
 * @export
 */
export const InterventionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterventionsApiFp(configuration)
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: any): AxiosPromise<TacheInterventionsControllerFindAll200Response> {
            return localVarFp.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne(id: number, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tacheInterventionsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterventionsApi - object-oriented interface
 * @export
 * @class InterventionsApi
 * @extends {BaseAPI}
 */
export class InterventionsApi extends BaseAPI {
    /**
     * 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterventionsApi
     */
    public tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return InterventionsApiFp(this.configuration).tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateAppel] 
     * @param {string} [refArticle] 
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterventionsApi
     */
    public tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig) {
        return InterventionsApiFp(this.configuration).tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterventionsApi
     */
    public tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return InterventionsApiFp(this.configuration).tacheInterventionsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterventionsApi
     */
    public tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig) {
        return InterventionsApiFp(this.configuration).tacheInterventionsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterventionsApi
     */
    public tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return InterventionsApiFp(this.configuration).tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MigrationApi - axios parameter creator
 * @export
 */
export const MigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMigrationControllerCreate: async (dataMigrationRequest: DataMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataMigrationRequest' is not null or undefined
            assertParamExists('clientsMigrationControllerCreate', 'dataMigrationRequest', dataMigrationRequest)
            const localVarPath = `/migration/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationArticlesControllerCreate: async (dataMigrationRequest: DataMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataMigrationRequest' is not null or undefined
            assertParamExists('migrationArticlesControllerCreate', 'dataMigrationRequest', dataMigrationRequest)
            const localVarPath = `/migration/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MigrationApi - functional programming interface
 * @export
 */
export const MigrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MigrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesClients>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMigrationControllerCreate(dataMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesPieces>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationArticlesControllerCreate(dataMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MigrationApi - factory interface
 * @export
 */
export const MigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MigrationApiFp(configuration)
    return {
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: any): AxiosPromise<Array<ListesClients>> {
            return localVarFp.clientsMigrationControllerCreate(dataMigrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataMigrationRequest} dataMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: any): AxiosPromise<Array<ListesPieces>> {
            return localVarFp.migrationArticlesControllerCreate(dataMigrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MigrationApi - object-oriented interface
 * @export
 * @class MigrationApi
 * @extends {BaseAPI}
 */
export class MigrationApi extends BaseAPI {
    /**
     * 
     * @param {DataMigrationRequest} dataMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public clientsMigrationControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).clientsMigrationControllerCreate(dataMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataMigrationRequest} dataMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public migrationArticlesControllerCreate(dataMigrationRequest: DataMigrationRequest, options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).migrationArticlesControllerCreate(dataMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OldApi - axios parameter creator
 * @export
 */
export const OldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate: async (listesPieces: ListesPieces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listesPieces' is not null or undefined
            assertParamExists('articlesControllerCreate', 'listesPieces', listesPieces)
            const localVarPath = `/old/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesPieces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindAll: async (name?: string, family?: string, status?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (family !== undefined) {
                localVarQueryParameter['family'] = family;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerFindOne', 'reference', reference)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/articles/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerRemove: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerRemove', 'reference', reference)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdate: async (reference: string, listesPieces: ListesPieces, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesControllerUpdate', 'reference', reference)
            // verify required parameter 'listesPieces' is not null or undefined
            assertParamExists('articlesControllerUpdate', 'listesPieces', listesPieces)
            const localVarPath = `/old/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesPieces, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-groups/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerAllow', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/allow/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-prohibited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerProhibit', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/prohibit/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate: async (listesClients: ListesClients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listesClients' is not null or undefined
            assertParamExists('clientsControllerCreate', 'listesClients', listesClients)
            const localVarPath = `/old/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesClients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll: async (status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerFindOne', 'refClient', refClient)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData: async (status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/clients/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemove: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerRemove', 'refClient', refClient)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate: async (refClient: string, listesClients: ListesClients, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'refClient', refClient)
            // verify required parameter 'listesClients' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'listesClients', listesClients)
            const localVarPath = `/old/clients/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listesClients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate: async (piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerCreate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll: async (dateAppel?: string, refArticle?: string, limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dateAppel !== undefined) {
                localVarQueryParameter['dateAppel'] = (dateAppel as any instanceof Date) ?
                    (dateAppel as any).toISOString() :
                    dateAppel;
            }

            if (refArticle !== undefined) {
                localVarQueryParameter['refArticle'] = refArticle;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerFindOne', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerRemove', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate: async (id: number, piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'id', id)
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerCreate: async (tachesDto: TachesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tachesDto' is not null or undefined
            assertParamExists('tachesControllerCreate', 'tachesDto', tachesDto)
            const localVarPath = `/old/taches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tachesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindAll: async (dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/taches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dateAppel !== undefined) {
                localVarQueryParameter['dateAppel'] = (dateAppel as any instanceof Date) ?
                    (dateAppel as any).toISOString() :
                    dateAppel;
            }

            if (dateDepan !== undefined) {
                localVarQueryParameter['dateDepan'] = (dateDepan as any instanceof Date) ?
                    (dateDepan as any).toISOString() :
                    dateDepan;
            }

            if (technician !== undefined) {
                localVarQueryParameter['technician'] = technician;
            }

            if (refClient !== undefined) {
                localVarQueryParameter['refClient'] = refClient;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindOne: async (dateAppel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerFindOne', 'dateAppel', dateAppel)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerRemove: async (dateAppel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerRemove', 'dateAppel', dateAppel)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerUpdate: async (dateAppel: string, tachesDto: TachesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerUpdate', 'dateAppel', dateAppel)
            // verify required parameter 'tachesDto' is not null or undefined
            assertParamExists('tachesControllerUpdate', 'tachesDto', tachesDto)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tachesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerCreate: async (zoneResa: ZoneResa, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneResa' is not null or undefined
            assertParamExists('techniciansControllerCreate', 'zoneResa', zoneResa)
            const localVarPath = `/old/technicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneResa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindAll: async (sector?: number, name?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/technicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (sector !== undefined) {
                localVarQueryParameter['sector'] = sector;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindOne: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerFindOne', 'name', name)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/technicians/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerRemove: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerRemove', 'name', name)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerUpdate: async (name: string, zoneResa: ZoneResa, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerUpdate', 'name', name)
            // verify required parameter 'zoneResa' is not null or undefined
            assertParamExists('techniciansControllerUpdate', 'zoneResa', zoneResa)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneResa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OldApi - functional programming interface
 * @export
 */
export const OldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerCreate(listesPieces: ListesPieces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerCreate(listesPieces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerFindAll(name, family, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesPieces>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerRemove(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerRemove(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesPieces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerUpdate(reference, listesPieces, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientGroups>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientGroupsControllerGetDropdownData(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerAllow(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerFindAll(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerProhibit(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerCreate(listesClients: ListesClients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerCreate(listesClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindAll(status, lookUp, group, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindOne(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindOne(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListesClients>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerGetDropdownData(status, lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerRemove(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerRemove(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListesClients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerUpdate(refClient, listesClients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerCreate(piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TacheInterventionsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerUpdate(id, piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerCreate(tachesDto: TachesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerCreate(tachesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TachesControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerFindOne(dateAppel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerFindOne(dateAppel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerRemove(dateAppel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerRemove(dateAppel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerUpdate(dateAppel, tachesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerCreate(zoneResa: ZoneResa, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerCreate(zoneResa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechniciansControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerFindAll(sector, name, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerFindOne(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerFindOne(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneResa>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerRemove(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerRemove(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerUpdate(name, zoneResa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OldApi - factory interface
 * @export
 */
export const OldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OldApiFp(configuration)
    return {
        /**
         * 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerCreate(listesPieces: ListesPieces, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerCreate(listesPieces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [family] 
         * @param {string} [status] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: any): AxiosPromise<ArticlesControllerFindAll200Response> {
            return localVarFp.articlesControllerFindAll(name, family, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerFindOne(reference: string, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetDropdownData(options?: any): AxiosPromise<Array<ListesPieces>> {
            return localVarFp.articlesControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerRemove(reference: string, options?: any): AxiosPromise<void> {
            return localVarFp.articlesControllerRemove(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {ListesPieces} listesPieces 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: any): AxiosPromise<ListesPieces> {
            return localVarFp.articlesControllerUpdate(reference, listesPieces, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lookUp] name, to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientGroupsControllerGetDropdownData(lookUp?: string, options?: any): AxiosPromise<Array<ClientGroups>> {
            return localVarFp.clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerAllow(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll(lookUp?: string, options?: any): AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>> {
            return localVarFp.clientProhibitedControllerFindAll(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerProhibit(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate(listesClients: ListesClients, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerCreate(listesClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {string} [group] group name to search for
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: any): AxiosPromise<ClientsControllerFindAll200Response> {
            return localVarFp.clientsControllerFindAll(status, lookUp, group, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne(refClient: string, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerFindOne(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
         * @param {string} [lookUp] name, nickname or reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: any): AxiosPromise<Array<ListesClients>> {
            return localVarFp.clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemove(refClient: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerRemove(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {ListesClients} listesClients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: any): AxiosPromise<ListesClients> {
            return localVarFp.clientsControllerUpdate(refClient, listesClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: any): AxiosPromise<TacheInterventionsControllerFindAll200Response> {
            return localVarFp.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne(id: number, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tacheInterventionsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerCreate(tachesDto: TachesDto, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerCreate(tachesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: any): AxiosPromise<TachesControllerFindAll200Response> {
            return localVarFp.tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindOne(dateAppel: string, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerFindOne(dateAppel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerRemove(dateAppel: string, options?: any): AxiosPromise<void> {
            return localVarFp.tachesControllerRemove(dateAppel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerUpdate(dateAppel, tachesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerCreate(zoneResa: ZoneResa, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerCreate(zoneResa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: any): AxiosPromise<TechniciansControllerFindAll200Response> {
            return localVarFp.techniciansControllerFindAll(sector, name, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindOne(name: string, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerFindOne(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData(options?: any): AxiosPromise<Array<ZoneResa>> {
            return localVarFp.techniciansControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerRemove(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.techniciansControllerRemove(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerUpdate(name, zoneResa, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OldApi - object-oriented interface
 * @export
 * @class OldApi
 * @extends {BaseAPI}
 */
export class OldApi extends BaseAPI {
    /**
     * 
     * @param {ListesPieces} listesPieces 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerCreate(listesPieces: ListesPieces, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerCreate(listesPieces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [family] 
     * @param {string} [status] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerFindAll(name?: string, family?: string, status?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerFindAll(name, family, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerGetDropdownData(options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerRemove(reference: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerRemove(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {ListesPieces} listesPieces 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public articlesControllerUpdate(reference: string, listesPieces: ListesPieces, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).articlesControllerUpdate(reference, listesPieces, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lookUp] name, to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientGroupsControllerGetDropdownData(lookUp?: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientGroupsControllerGetDropdownData(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientProhibitedControllerAllow(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lookUp] reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientProhibitedControllerFindAll(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientProhibitedControllerProhibit(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListesClients} listesClients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerCreate(listesClients: ListesClients, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerCreate(listesClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
     * @param {string} [lookUp] name, nickname or reference to search for
     * @param {string} [group] group name to search for
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerFindAll(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, group?: string, limit?: any, page?: any, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerFindAll(status, lookUp, group, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerFindOne(refClient: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerFindOne(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all'} [status] 
     * @param {string} [lookUp] name, nickname or reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerGetDropdownData(status?: 'enabled' | 'disabled' | 'allowed' | 'blocked' | 'all', lookUp?: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerGetDropdownData(status, lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerRemove(refClient: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerRemove(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {ListesClients} listesClients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public clientsControllerUpdate(refClient: string, listesClients: ListesClients, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).clientsControllerUpdate(refClient, listesClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateAppel] 
     * @param {string} [refArticle] 
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tacheInterventionsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tacheInterventionsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TachesDto} tachesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tachesControllerCreate(tachesDto: TachesDto, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tachesControllerCreate(tachesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateAppel] 
     * @param {string} [dateDepan] 
     * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
     * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
     * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tachesControllerFindOne(dateAppel: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tachesControllerFindOne(dateAppel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tachesControllerRemove(dateAppel: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tachesControllerRemove(dateAppel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {TachesDto} tachesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).tachesControllerUpdate(dateAppel, tachesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneResa} zoneResa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerCreate(zoneResa: ZoneResa, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerCreate(zoneResa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [sector] 
     * @param {string} [name] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerFindAll(sector, name, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerFindOne(name: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerFindOne(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerGetDropdownData(options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerRemove(name: string, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerRemove(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {ZoneResa} zoneResa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OldApi
     */
    public techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: AxiosRequestConfig) {
        return OldApiFp(this.configuration).techniciansControllerUpdate(name, zoneResa, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProhibitApi - axios parameter creator
 * @export
 */
export const ProhibitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerAllow', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/allow/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll: async (lookUp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/client-prohibited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (lookUp !== undefined) {
                localVarQueryParameter['lookUp'] = lookUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit: async (refClient: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refClient' is not null or undefined
            assertParamExists('clientProhibitedControllerProhibit', 'refClient', refClient)
            const localVarPath = `/old/client-prohibited/prohibit/{refClient}`
                .replace(`{${"refClient"}}`, encodeURIComponent(String(refClient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProhibitApi - functional programming interface
 * @export
 */
export const ProhibitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProhibitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerAllow(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerFindAll(lookUp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProhibitedControllerProhibit(refClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProhibitApi - factory interface
 * @export
 */
export const ProhibitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProhibitApiFp(configuration)
    return {
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerAllow(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerAllow(refClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lookUp] reference to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerFindAll(lookUp?: string, options?: any): AxiosPromise<Array<ClientProhibitedControllerFindAll200ResponseInner>> {
            return localVarFp.clientProhibitedControllerFindAll(lookUp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refClient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProhibitedControllerProhibit(refClient: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.clientProhibitedControllerProhibit(refClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProhibitApi - object-oriented interface
 * @export
 * @class ProhibitApi
 * @extends {BaseAPI}
 */
export class ProhibitApi extends BaseAPI {
    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProhibitApi
     */
    public clientProhibitedControllerAllow(refClient: string, options?: AxiosRequestConfig) {
        return ProhibitApiFp(this.configuration).clientProhibitedControllerAllow(refClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lookUp] reference to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProhibitApi
     */
    public clientProhibitedControllerFindAll(lookUp?: string, options?: AxiosRequestConfig) {
        return ProhibitApiFp(this.configuration).clientProhibitedControllerFindAll(lookUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refClient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProhibitApi
     */
    public clientProhibitedControllerProhibit(refClient: string, options?: AxiosRequestConfig) {
        return ProhibitApiFp(this.configuration).clientProhibitedControllerProhibit(refClient, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SageApi - axios parameter creator
 * @export
 */
export const SageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindAll: async (referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sage/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (referenceFrom !== undefined) {
                localVarQueryParameter['referenceFrom'] = referenceFrom;
            }

            if (referenceTo !== undefined) {
                localVarQueryParameter['referenceTo'] = referenceTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('articlesSageControllerFindOne', 'reference', reference)
            const localVarPath = `/sage/articles/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindAll: async (referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sage/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (referenceFrom !== undefined) {
                localVarQueryParameter['referenceFrom'] = referenceFrom;
            }

            if (referenceTo !== undefined) {
                localVarQueryParameter['referenceTo'] = referenceTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindOne: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('clientsSageControllerFindOne', 'reference', reference)
            const localVarPath = `/sage/clients/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SageApi - functional programming interface
 * @export
 */
export const SageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesSageControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesSageControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VDevRavimoArticles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesSageControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsSageControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsSageControllerFindOne(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VDevRavimoClientsFinal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsSageControllerFindOne(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SageApi - factory interface
 * @export
 */
export const SageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: any): AxiosPromise<ArticlesSageControllerFindAll200Response> {
            return localVarFp.articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesSageControllerFindOne(reference: string, options?: any): AxiosPromise<VDevRavimoArticles> {
            return localVarFp.articlesSageControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [referenceFrom] 
         * @param {string} [referenceTo] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: any): AxiosPromise<ClientsSageControllerFindAll200Response> {
            return localVarFp.clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsSageControllerFindOne(reference: string, options?: any): AxiosPromise<VDevRavimoClientsFinal> {
            return localVarFp.clientsSageControllerFindOne(reference, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SageApi - object-oriented interface
 * @export
 * @class SageApi
 * @extends {BaseAPI}
 */
export class SageApi extends BaseAPI {
    /**
     * 
     * @param {string} [referenceFrom] 
     * @param {string} [referenceTo] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SageApi
     */
    public articlesSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return SageApiFp(this.configuration).articlesSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SageApi
     */
    public articlesSageControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return SageApiFp(this.configuration).articlesSageControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [referenceFrom] 
     * @param {string} [referenceTo] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SageApi
     */
    public clientsSageControllerFindAll(referenceFrom?: string, referenceTo?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return SageApiFp(this.configuration).clientsSageControllerFindAll(referenceFrom, referenceTo, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SageApi
     */
    public clientsSageControllerFindOne(reference: string, options?: AxiosRequestConfig) {
        return SageApiFp(this.configuration).clientsSageControllerFindOne(reference, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TachesApi - axios parameter creator
 * @export
 */
export const TachesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate: async (piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerCreate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll: async (dateAppel?: string, refArticle?: string, limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/tache-interventions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dateAppel !== undefined) {
                localVarQueryParameter['dateAppel'] = (dateAppel as any instanceof Date) ?
                    (dateAppel as any).toISOString() :
                    dateAppel;
            }

            if (refArticle !== undefined) {
                localVarQueryParameter['refArticle'] = refArticle;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerFindOne', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerRemove', 'id', id)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate: async (id: number, piecesIntervention: PiecesIntervention, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'id', id)
            // verify required parameter 'piecesIntervention' is not null or undefined
            assertParamExists('tacheInterventionsControllerUpdate', 'piecesIntervention', piecesIntervention)
            const localVarPath = `/old/tache-interventions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piecesIntervention, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerCreate: async (tachesDto: TachesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tachesDto' is not null or undefined
            assertParamExists('tachesControllerCreate', 'tachesDto', tachesDto)
            const localVarPath = `/old/taches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tachesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindAll: async (dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/taches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dateAppel !== undefined) {
                localVarQueryParameter['dateAppel'] = (dateAppel as any instanceof Date) ?
                    (dateAppel as any).toISOString() :
                    dateAppel;
            }

            if (dateDepan !== undefined) {
                localVarQueryParameter['dateDepan'] = (dateDepan as any instanceof Date) ?
                    (dateDepan as any).toISOString() :
                    dateDepan;
            }

            if (technician !== undefined) {
                localVarQueryParameter['technician'] = technician;
            }

            if (refClient !== undefined) {
                localVarQueryParameter['refClient'] = refClient;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindOne: async (dateAppel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerFindOne', 'dateAppel', dateAppel)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerRemove: async (dateAppel: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerRemove', 'dateAppel', dateAppel)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerUpdate: async (dateAppel: string, tachesDto: TachesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateAppel' is not null or undefined
            assertParamExists('tachesControllerUpdate', 'dateAppel', dateAppel)
            // verify required parameter 'tachesDto' is not null or undefined
            assertParamExists('tachesControllerUpdate', 'tachesDto', tachesDto)
            const localVarPath = `/old/taches/{dateAppel}`
                .replace(`{${"dateAppel"}}`, encodeURIComponent(String(dateAppel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tachesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TachesApi - functional programming interface
 * @export
 */
export const TachesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TachesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerCreate(piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TacheInterventionsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesIntervention>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tacheInterventionsControllerUpdate(id, piecesIntervention, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerCreate(tachesDto: TachesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerCreate(tachesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TachesControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerFindOne(dateAppel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerFindOne(dateAppel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerRemove(dateAppel: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerRemove(dateAppel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tachesControllerUpdate(dateAppel, tachesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TachesApi - factory interface
 * @export
 */
export const TachesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TachesApiFp(configuration)
    return {
        /**
         * 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [refArticle] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: any): AxiosPromise<TacheInterventionsControllerFindAll200Response> {
            return localVarFp.tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerFindOne(id: number, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tacheInterventionsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PiecesIntervention} piecesIntervention 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: any): AxiosPromise<PiecesIntervention> {
            return localVarFp.tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerCreate(tachesDto: TachesDto, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerCreate(tachesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateAppel] 
         * @param {string} [dateDepan] 
         * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
         * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
         * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
         * @param {any} [limit] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: any): AxiosPromise<TachesControllerFindAll200Response> {
            return localVarFp.tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerFindOne(dateAppel: string, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerFindOne(dateAppel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerRemove(dateAppel: string, options?: any): AxiosPromise<void> {
            return localVarFp.tachesControllerRemove(dateAppel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateAppel 
         * @param {TachesDto} tachesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: any): AxiosPromise<Taches> {
            return localVarFp.tachesControllerUpdate(dateAppel, tachesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TachesApi - object-oriented interface
 * @export
 * @class TachesApi
 * @extends {BaseAPI}
 */
export class TachesApi extends BaseAPI {
    /**
     * 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tacheInterventionsControllerCreate(piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tacheInterventionsControllerCreate(piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateAppel] 
     * @param {string} [refArticle] 
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tacheInterventionsControllerFindAll(dateAppel?: string, refArticle?: string, limit?: any, page?: any, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tacheInterventionsControllerFindAll(dateAppel, refArticle, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tacheInterventionsControllerFindOne(id: number, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tacheInterventionsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tacheInterventionsControllerRemove(id: number, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tacheInterventionsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PiecesIntervention} piecesIntervention 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tacheInterventionsControllerUpdate(id: number, piecesIntervention: PiecesIntervention, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tacheInterventionsControllerUpdate(id, piecesIntervention, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TachesDto} tachesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tachesControllerCreate(tachesDto: TachesDto, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tachesControllerCreate(tachesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateAppel] 
     * @param {string} [dateDepan] 
     * @param {string} [technician] name or sector of technician or a list of them separated by comma. See Examples
     * @param {string} [refClient] reference of client or a list of references separated by comma. See Examples
     * @param {'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office'} [status] 
     * @param {any} [limit] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tachesControllerFindAll(dateAppel?: string, dateDepan?: string, technician?: string, refClient?: string, status?: 'not_started' | 'in_progress' | 'finished_by_technician' | 'finished_by_office', limit?: any, page?: any, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tachesControllerFindAll(dateAppel, dateDepan, technician, refClient, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tachesControllerFindOne(dateAppel: string, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tachesControllerFindOne(dateAppel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tachesControllerRemove(dateAppel: string, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tachesControllerRemove(dateAppel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateAppel 
     * @param {TachesDto} tachesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TachesApi
     */
    public tachesControllerUpdate(dateAppel: string, tachesDto: TachesDto, options?: AxiosRequestConfig) {
        return TachesApiFp(this.configuration).tachesControllerUpdate(dateAppel, tachesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechnicianApi - axios parameter creator
 * @export
 */
export const TechnicianApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerCreate: async (zoneResa: ZoneResa, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneResa' is not null or undefined
            assertParamExists('techniciansControllerCreate', 'zoneResa', zoneResa)
            const localVarPath = `/old/technicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneResa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindAll: async (sector?: number, name?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/technicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (sector !== undefined) {
                localVarQueryParameter['sector'] = sector;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindOne: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerFindOne', 'name', name)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/old/technicians/dropdown-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerRemove: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerRemove', 'name', name)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerUpdate: async (name: string, zoneResa: ZoneResa, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('techniciansControllerUpdate', 'name', name)
            // verify required parameter 'zoneResa' is not null or undefined
            assertParamExists('techniciansControllerUpdate', 'zoneResa', zoneResa)
            const localVarPath = `/old/technicians/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneResa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechnicianApi - functional programming interface
 * @export
 */
export const TechnicianApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechnicianApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerCreate(zoneResa: ZoneResa, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerCreate(zoneResa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechniciansControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerFindAll(sector, name, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerFindOne(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerFindOne(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerGetDropdownData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneResa>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerGetDropdownData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerRemove(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerRemove(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.techniciansControllerUpdate(name, zoneResa, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechnicianApi - factory interface
 * @export
 */
export const TechnicianApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechnicianApiFp(configuration)
    return {
        /**
         * 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerCreate(zoneResa: ZoneResa, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerCreate(zoneResa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [sector] 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: any): AxiosPromise<TechniciansControllerFindAll200Response> {
            return localVarFp.techniciansControllerFindAll(sector, name, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerFindOne(name: string, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerFindOne(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerGetDropdownData(options?: any): AxiosPromise<Array<ZoneResa>> {
            return localVarFp.techniciansControllerGetDropdownData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerRemove(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.techniciansControllerRemove(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {ZoneResa} zoneResa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: any): AxiosPromise<ZoneResa> {
            return localVarFp.techniciansControllerUpdate(name, zoneResa, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TechnicianApi - object-oriented interface
 * @export
 * @class TechnicianApi
 * @extends {BaseAPI}
 */
export class TechnicianApi extends BaseAPI {
    /**
     * 
     * @param {ZoneResa} zoneResa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerCreate(zoneResa: ZoneResa, options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerCreate(zoneResa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [sector] 
     * @param {string} [name] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerFindAll(sector?: number, name?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerFindAll(sector, name, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerFindOne(name: string, options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerFindOne(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerGetDropdownData(options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerGetDropdownData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerRemove(name: string, options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerRemove(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {ZoneResa} zoneResa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianApi
     */
    public techniciansControllerUpdate(name: string, zoneResa: ZoneResa, options?: AxiosRequestConfig) {
        return TechnicianApiFp(this.configuration).techniciansControllerUpdate(name, zoneResa, options).then((request) => request(this.axios, this.basePath));
    }
}


