import {
  ClientStatus,
  ClientsApi,
  ClientsControllerFindAll200Response,
  DataMigrationRequest,
  ListesClients,
} from '@app/api/ravimoshark/lib';

import data from './response.json';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { mergeArrays } from '@app/utils/array.utils';

export type clientsFilterParams = {
  status?: ClientStatus;
  lookUp?: string;
  limit?: number;
  page?: number;
};

type RequiredClientsFilterParams = {
  [K in keyof clientsFilterParams]-?: clientsFilterParams[K];
};

const clientsFilterParamsInstance: RequiredClientsFilterParams = {
  status: ClientStatus.All,
  lookUp: '',
  limit: 0,
  page: 0,
};

export const clientsFilterFields: (keyof RequiredClientsFilterParams)[] = Object.keys(
  clientsFilterParamsInstance,
) as (keyof RequiredClientsFilterParams)[];

export const getClients = ({
  clientAPI,
  status,
  lookUp,
  page,
  limit,
}: { clientAPI?: ClientsApi } & clientsFilterParams): Promise<
  Partial<AxiosResponse<ClientsControllerFindAll200Response>>
> =>
  clientAPI
    ? clientAPI.clientsControllerFindAll(status, lookUp, undefined, limit, page)
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: data as ClientsControllerFindAll200Response, status: 200 });
        }, 1000);
      });

export const getClient = ({
  referenceClient,
  clientAPI,
  data,
}: { data?: Partial<AxiosResponse<ClientsControllerFindAll200Response> | undefined> } & deleteClientsProps): Promise<
  Partial<AxiosResponse<ListesClients>>
> => {
  return clientAPI
    ? clientAPI.clientsControllerFindOne(referenceClient)
    : new Promise((res) => {
        setTimeout(() => {
          res({ status: 200, data: data?.data?.items?.find((t) => t.referenceClient === referenceClient) });
        }, 1000);
      });
};

type migrateClientsProps = {
  record: DataMigrationRequest;
} & clientsProps;

export const migrateClients = ({
  applyChangesToData,
  record,
  clientAPI,
  setData,
  setGlobalData,
}: migrateClientsProps): Promise<Partial<AxiosResponse<ListesClients[]>>> =>
  clientAPI
    ? clientAPI.clientsMigrationControllerCreate(record).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: mergeArrays(data?.data?.items || [], response.data, 'referenceClient') },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: mergeArrays(d?.data || [], response.data, 'referenceClient'),
          }));
        }
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          res({ status: 200, data: data.items as ListesClients[] });
        }, 1000);
      });

export const getClientsSelector = (
  clientAPI?: ClientsApi,
  status?: ClientStatus,
): Promise<Partial<AxiosResponse<ListesClients[]>>> =>
  clientAPI
    ? clientAPI.clientsControllerGetDropdownData(status)
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: data.items as ListesClients[], status: 200 });
        }, 1000);
      });

type deleteClientsProps = {
  referenceClient: string;
} & clientsProps;

export const deleteClients = ({
  referenceClient,
  clientAPI,
  setData,
  setGlobalData,
  applyChangesToData,
}: deleteClientsProps): Promise<Partial<AxiosResponse<void>>> => {
  return clientAPI
    ? clientAPI.clientsControllerRemove(referenceClient).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: data?.data?.items?.filter((c) => c.referenceClient !== referenceClient) },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.filter((c) => c.referenceClient !== referenceClient),
          }));
        }
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: data?.data?.items?.filter((c) => c.referenceClient !== referenceClient) },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.filter((c) => c.referenceClient !== referenceClient),
          }));
          res({ status: 200 });
        }, 1000);
      });
};

type editClientsProps = {
  referenceClient: string;
} & addClientProps;

export const editClient = ({
  referenceClient,
  record,
  clientAPI,
  setData,
  setGlobalData,
  applyChangesToData,
}: editClientsProps): Promise<Partial<AxiosResponse<ListesClients>>> => {
  return clientAPI
    ? clientAPI.clientsControllerUpdate(referenceClient, record).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceClient === referenceClient ? response.data : c)),
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.map((c) => (c.referenceClient === referenceClient ? response.data : c)),
          }));
        }
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceClient === referenceClient ? record : c)),
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.map((c) => (c.referenceClient === referenceClient ? record : c)),
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};

type clientsProps = {
  clientAPI?: ClientsApi;
  setData?: Dispatch<SetStateAction<Partial<AxiosResponse<ClientsControllerFindAll200Response> | undefined>>>;
  setGlobalData?: Dispatch<SetStateAction<Partial<AxiosResponse<ListesClients[]> | undefined>>>;
  applyChangesToData: boolean;
};

type addClientProps = {
  record: ListesClients;
} & clientsProps;

export const addClient = ({
  record,
  clientAPI,
  setData,
  setGlobalData,
  applyChangesToData,
}: addClientProps): Promise<Partial<AxiosResponse<ListesClients>>> => {
  return clientAPI
    ? clientAPI.clientsControllerCreate(record).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: [{ ...response.data, status: ClientStatus.Enabled }, ...(data?.data?.items || [])],
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: [response.data, ...(d?.data || [])],
          }));
        }
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: [record, ...(data?.data?.items || [])] },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: [record, ...(d?.data || [])],
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};

export const allowClient = ({
  referenceClient,
  clientAPI,
  setData,
  setGlobalData,
  applyChangesToData,
}: deleteClientsProps): Promise<Partial<AxiosResponse<boolean>>> => {
  return clientAPI
    ? clientAPI.clientProhibitedControllerAllow(referenceClient).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) =>
                c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Allowed } : c,
              ),
            },
          }));
        }
        setGlobalData?.((d) => ({
          ...d,
          data: d?.data?.map((c) =>
            c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Allowed } : c,
          ),
        }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) =>
                c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Allowed } : c,
              ),
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.map((c) =>
              c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Allowed } : c,
            ),
          }));
          res({ status: 200 });
        }, 1000);
      });
};

export const blockClient = ({
  referenceClient,
  clientAPI,
  setData,
  setGlobalData,
  applyChangesToData,
}: deleteClientsProps): Promise<Partial<AxiosResponse<boolean>>> => {
  return clientAPI
    ? clientAPI.clientProhibitedControllerProhibit(referenceClient).then((response) => {
        if (applyChangesToData) {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) =>
                c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Blocked } : c,
              ),
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.map((c) =>
              c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Blocked } : c,
            ),
          }));
        }
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) =>
                c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Blocked } : c,
              ),
            },
          }));
          setGlobalData?.((d) => ({
            ...d,
            data: d?.data?.map((c) =>
              c.referenceClient === referenceClient ? { ...c, status: ClientStatus.Blocked } : c,
            ),
          }));
          res({ status: 200 });
        }, 1000);
      });
};
